import React, { FunctionComponent, useRef } from "react";
import { useSelector } from "react-redux";
import { getCode } from "../JavascriptVM/vmSlice";
import SyntaxHighlighter from "react-syntax-highlighter";

import "./SourceView.css";
import { BLOCKLY_HIGHLIGHT_PREFIX } from "../BlocklyInterface/BlocklyInstance";

/**
 * Removes any occurrences of the BLOCKLY_HIGHLIGHT_PREFIX in the
 * provided code.
 *
 * @param blocklyCode Code that might contain BLOCKLY_HIGHLIGHT_PREFIX
 * statements.
 *
 * @returns A string that is identical to the provided 'blocklyCode',
 * but without BLOCKLY_HIGHLIGHT_PREFIX statements.
 */
function removeHighlightBlockCalls(blocklyCode: string | null): string {
  if (!blocklyCode) {
    return "";
  }
  return blocklyCode
    .split("\n")
    .filter((line) => !line.includes(BLOCKLY_HIGHLIGHT_PREFIX))
    .join("\n");
}

/**
 * Read-only view for the source code generated by Blockly.
 */
export const SourceView: FunctionComponent = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const code = useSelector(getCode);

  return (
    <div
      ref={wrapperRef}
      className={"source-view"}
      title={"Generated source code is read-only"}
    >
      <SyntaxHighlighter
        language="javascript"
        showLineNumbers={true}
        customStyle={{ margin: 0, padding: "1em", height: "100%" }}
      >
        {removeHighlightBlockCalls(code)}
      </SyntaxHighlighter>
    </div>
  );
};
